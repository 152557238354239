import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { Link } from "gatsby";

import Layout from "../../components/layout";

import Img from "gatsby-image";

export default function SocialImpact() {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "1-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blog: file(relativePath: { eq: "riverBG.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mondayMotivation: file(relativePath: { eq: "monday-motivation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featured: file(relativePath: { eq: "featured.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            videos
            title
            featuredText
            author
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const background = data["background"].childImageSharp.fluid;

  const blogCard = data["blog"].childImageSharp.fluid;

  const mondayMotivation = data["mondayMotivation"].childImageSharp.fluid;

  const featured = data["featured"].childMarkdownRemark;

  return (
    <Layout>
      <h2 className="pr-5 section-header text-center">Learn and Grow</h2>
      <div className="relative">
        <div className="absolute inset-0">
          <Img
            fluid={background}
            alt="background"
            className="h-full"
            imgStyle={{
              zIndex: -1,
              objectPosition: "0% 0",
              filter: "brightness(0.6)",
            }}
          />
        </div>
        <p className="pt-32 text-center text-3xl max-w-3xl m-auto text-shadow italic">
        We are all together on this journey of life. We are all trying to find answers to 
        the questions we have. We are all just looking for a place to fit in. I’m hoping 
        this will become a community where we share our answers to the questions of life 
        and become part of the solution to the problems in the world. 
        </p>
        <h3 className="text-5xl text-center pb-32 pt-16 text-shadow">
          Blog | Important Stories | Podcast
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-16 lg:gap-32 max-w-7xl m-auto pb-24 px-5 lg:px-16">
          <SectionCard title="blog" image={blogCard} link="blog" />
          <SectionCard
            title={featured.frontmatter.title}
            image={featured.frontmatter.featuredImage.childImageSharp.fluid}
            link="featured"
          />
          <SectionCard
            title="Monday motivation"
            image={mondayMotivation}
            link="monday-motivation"
          />
        </div>
      </div>
    </Layout>
  );
}

function SectionCard({ title, image, link }) {
  return (
    <div className="relative h-full video-shadow transform hover:scale-110 transition-transform">
      <Link to={link}>
        <div className="absolute inset-0">
          <Img
            fluid={image}
            className="h-full"
            alt={title}
            imgStyle={{
              zIndex: -1,
            }}
          />
        </div>
        <div className="h-full inset-0 flex justify-center">
          <h3 className="text-3xl py-32 text-shadow text-center">{title}</h3>
        </div>
      </Link>
    </div>
  );
}
